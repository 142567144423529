export function DOMStringToNodeArray (str) {
  const dom = (new DOMParser()).parseFromString(str, 'text/html')
  return Array.from(dom.body.children)
}

export function scrollActionBasedOnDirection (up, down) {
  let lastScrollTop = 0
  window.addEventListener('scroll', function () {
    const st = window.pageYOffset || document.documentElement.scrollTop
    st > lastScrollTop ? down() : up()
    lastScrollTop = st <= 0 ? 0 : st
  }, { capture: false, passive: true })
}

export function onOutsideClick (elem, callback) {
  if (!elem) { return }
  window.addEventListener('click', function (e) {
    if (!elem.contains(e.target)) {
      return callback()
    }
  })
}

export function detectIfScrollOnTop (yesCallback, noCallback) {
  window.addEventListener('scroll', function () {
    const body = window.document.body // IE 'quirks'
    let document = window.document.documentElement // IE with doctype
    document = (document.clientHeight) ? document : body

    if (document.scrollTop === 0) yesCallback() // eslint-disable-line curly
    else noCallback() // eslint-disable-line curly
  }, { passive: true })
}

export function detectIfScrollOnTopElement (elementTarget, yesCallback, noCallback) {
  window.addEventListener('scroll', function () {
    const body = window.document.body
    let document = window.document.documentElement
    document = (document.clientHeight) ? document : body
    if (document.scrollTop < 300) yesCallback() // eslint-disable-line curly
    else noCallback() // eslint-disable-line curly
  }, { passive: true })
}

export function detectScrollDirection (downCallback, upCallback) {
  let oldValue = 0
  let newValue = 0
  window.addEventListener('scroll', (e) => {
    newValue = window.pageYOffset
    if (oldValue < newValue) {
      upCallback()
    } else if (oldValue > newValue) {
      downCallback()
    }
    oldValue = newValue
  }, { passive: true })
}

/**
 * USAGE
 * https://jsfiddle.net/KarmaProd/tgn9d1uL/4/
 *
 * setInputFilter(document.getElementById("intTextBox"), function(value) {
  return /^-?\d*$/.test(value); }, "Must be an integer");
setInputFilter(document.getElementById("uintTextBox"), function(value) {
  return /^\d*$/.test(value); }, "Must be an unsigned integer");
setInputFilter(document.getElementById("intLimitTextBox"), function(value) {
  return /^\d*$/.test(value) && (value === "" || parseInt(value) <= 500); }, "Must be between 0 and 500");
setInputFilter(document.getElementById("floatTextBox"), function(value) {
  return /^-?\d*[.,]?\d*$/.test(value); }, "Must be a floating (real) number");
setInputFilter(document.getElementById("currencyTextBox"), function(value) {
  return /^-?\d*[.,]?\d{0,2}$/.test(value); }, "Must be a currency value");
setInputFilter(document.getElementById("latinTextBox"), function(value) {
  return /^[a-z]*$/i.test(value); }, "Must use alphabetic latin characters");
setInputFilter(document.getElementById("hexTextBox"), function(value) {
  return /^[0-9a-f]*$/i.test(value); }, "Must use hexadecimal characters");
 *
 */

export function setInputFilter (textbox, max, min, strict, inputFilter, validCallback, invalidCallback, errMsg='') {
  if(!textbox) return;
  ['input', 'focusout'].forEach(function (event) {
    textbox.addEventListener(event, function (e) {
      
      if(!this.value) {
        this.value = ''
        this.oldValue = ''
        return
      }

      if (inputFilter(this.value)) {
        if (['keydown', 'mousedown', 'focusout'].includes(e.type)) {
          this.classList.remove('input-error')
        }
        if (strict && this.value.length > 1 && this.value.charAt(0) === '0') { this.value = this.value.substring(1) }
        if(strict) this.oldValue = this.value
        this.oldSelectionStart = this.selectionStart
        this.oldSelectionEnd = this.selectionEnd
        validCallback()
      } else if (this.hasOwnProperty('oldValue') && this.oldValue) {
        if(!strict) invalidCallback()
        if(max && this.value > max) this.oldValue = max 
        if(min && this.value > min) this.oldValue = min
        if(strict) this.value = this.oldValue
        this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd)
      } else {
        // Rejected value - nothing to restore
        // this.value = ''
        invalidCallback()
      }
    })
  })
}

export function loadCss (filename) {
  const cssNode = document.createElement('link')
  cssNode.setAttribute('rel', 'stylesheet')
  cssNode.setAttribute('type', 'text/css')
  cssNode.setAttribute('href', filename)
  document.getElementsByTagName('head')[0].appendChild(cssNode)
}

export function scrollPastElement (elementTarget, callback) {
  window.addEventListener('scroll', function () {
    if (window.scrollY > (elementTarget.offsetTop + elementTarget.offsetHeight)) {
      callback()
    }
  }, { capture: false, passive: true })
}

export function loadScript (src, id, callback) {
  const scriptElem = document.createElement('script')
  scriptElem.onload = () => {
    callback()
  }
  scriptElem.setAttribute('src', src)
  scriptElem.setAttribute('id', id)
  document.head.appendChild(scriptElem)
}

export function downloadFromUrl (dataurl, filename) {
  const link = document.createElement('a')
  link.href = dataurl
  link.download = filename
  link.click()
}

export function elementInViewport (el, offset) {
  let top = el.offsetTop
  const height = el.offsetHeight

  while (el.offsetParent) {
    el = el.offsetParent
    top += el.offsetTop
  }

  return (
    (top + height) <= (window.pageYOffset + window.innerHeight) - offset
  )
}
export function getFirstHeading(html) {
  var match = html.match(/<h[1-6]>(.*?)<\/h[1-6]>/i);
  if (match) {
    return match[0];
  } else {
    return null;
  }
}

export function removeFirstHeading(html) {
  var match = html.match(/<h[1-6](.*?)>(.*?)<\/h[1-6]>/i);
  if (match) {
    return html.replace(match[0], "");
  } else {
    return html;
  }
}

export function waitForElementToMountInDOM(queryString, callback) {
  const targetNode = document.body;
  const observer = new MutationObserver(function(mutationsList, observer) {
      const element = document.querySelector(queryString);
      if (element) {
          callback(element);
          observer.disconnect();
      }
  });
  const config = { childList: true, subtree: true };
  observer.observe(targetNode, config);
}
